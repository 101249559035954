import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ReportOptions } from '@/models/report/interfaces';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { ReportFileType, ReportType } from '@/models/report/enums';
import { useCalculation } from '@/modules/calculation';
import { createReportName, downloadExcel } from '@/helpers/reports';
import { utils, write } from 'xlsx';
import {
  DEFAULT_TEXT_MEDIUM,
  DEFAULT_TEXT_XSMALL,
  LARGE_TEXT_MEDIUM,
  LARGE_TEXT_XSMALL,
} from '@/constants/styles';
import { useReport } from '@/modules/report';

const generateAttachmentPriceOfferReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();

  if (!projectCalculation.value) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);

  const pdfName = createReportName(type, ReportFileType.PDF);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;

  const buildingElementBreakPoints: Array<number> = [];

  const reportName = `${translate('reports.attachment-price-offer-report')}`;

  doc.text(reportName, 14, first.finalY + 9);

  const head = [
    [
      translate('labels.zip'),
      translate('labels.description'),
      translate('labels.comments'),
      translate('labels.unit'),
      translate('labels.amount'),
      translate('labels.sum'),
    ],
  ];
  const offerData: (string | number)[][] = [];

  let elements = projectCalculation.value.BuildingElements.filter(
    i =>
      (i.Amount && i.Amount > 0) ||
      (i.UnitPrice?.value && i.UnitPrice.value > 0)
  );

  if (elements) {
    let postnr = 1;
    elements = elements.sort((a, b) =>
      (a.SortOrder ?? -1) > (b.SortOrder ?? -1)
        ? 1
        : (b.SortOrder ?? -1) > (a.SortOrder ?? -1)
        ? -1
        : 0
    );

    elements.forEach(ele => {
      const row = [
        '',
        ele.Name ?? '',
        options.IncludeComments ? ele.Comments ?? '' : '',
        ele.Unit?.value ?? '',
        options.IncludeAmounts
          ? ele.Amount?.toLocaleString(defaultLocaleString, {
              maximumFractionDigits: 2,
            }) ?? ''
          : '',
        options.IncludeElementSums && ele.SumTotal.value
          ? ele.SumTotal.value.toLocaleString(defaultLocaleString, {
              maximumFractionDigits: 2,
            })
          : '',
      ];

      buildingElementBreakPoints.push(offerData.length);
      offerData.push(row);

      let filteredItems = ele.BuildingItems.filter(
        i =>
          (i.Amount && i.Amount > 0) ||
          (i.UnitPrice.value && i.UnitPrice.value > 0)
      );

      filteredItems = filteredItems.sort((a, b) =>
        (a.BuildingElement?.SortOrder ?? -1) >
        (b.BuildingElement?.SortOrder ?? -1)
          ? 1
          : (b.BuildingElement?.SortOrder ?? -1) >
            (a.BuildingElement?.SortOrder ?? -1)
          ? -1
          : 0
      );

      if (options.IncludeBuildingItems) {
        filteredItems.forEach(item => {
          const row = [
            postnr++,
            `${item.Name ?? ''} ${
              item.PriceListItemName && options.ShowProductName
                ? `(${item.PriceListItemName})`
                : ''
            }`,
            options.IncludeComments ? item.Comments ?? '' : '',
            item.Unit ?? '',
            options.IncludeAmounts
              ? item.AmountCalculated.value?.toLocaleString(
                  defaultLocaleString,
                  {
                    maximumFractionDigits: 2,
                  }
                ) ?? ''
              : '',
            options.IncludeItemSums && item.SumTotalWithAdditions.value
              ? item.SumTotalWithAdditions.value.toLocaleString(
                  defaultLocaleString,
                  {
                    maximumFractionDigits: 2,
                  }
                )
              : '',
          ];

          offerData.push(row);
        });
      }
    });

    const MVA = 0.25;
    let sumExclMva = 0;
    let sumInclMva = 0;
    let MvaOnly = 0;

    elements.forEach(ele => {
      sumExclMva += ele.SumTotal.value ?? 0;
    });

    MvaOnly = sumExclMva * MVA;
    sumInclMva = sumExclMva + MvaOnly;

    const fillRow = ['', '', '', '', ''];
    const sumExclMvaRow = [
      '',
      '',
      '',
      '',
      `${translate('labels.sum-exclude-mva')}`,
      sumExclMva.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 2,
      }),
    ];
    const mvaRow = [
      '',
      '',
      '',
      '',
      `${translate('labels.sum-mva-only')}`,
      MvaOnly.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 2,
      }),
    ];
    const sumInclMvaRow = [
      '',
      '',
      '',
      '',
      `${translate('labels.sum-include-mva')}`,
      sumInclMva.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 2,
      }),
    ];

    offerData.push(fillRow);
    offerData.push(sumExclMvaRow);
    offerData.push(mvaRow);
    offerData.push(sumInclMvaRow);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const elePos = (doc as any).autoTable.previous;

    autoTable(doc, {
      startY: elePos.finalY + 14,
      head: head,
      body: offerData,
      showHead: 'firstPage',
      headStyles: { fillColor: '#263238' },
      styles: {
        fontSize:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_XSMALL
            : LARGE_TEXT_XSMALL,
      },
      didParseCell(data) {
        if (
          buildingElementBreakPoints.includes(data.row.index) &&
          data.section === 'body'
        ) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = options.IncludeBuildingItems
            ? '#d6d8da'
            : 'white';
        } else if (
          (data.section === 'body' &&
            data.row.index === offerData.length - 1) ||
          data.row.index === offerData.length - 2 ||
          data.row.index === offerData.length - 3
        ) {
          data.row.cells[0].styles.fillColor = 'white';
          data.row.cells[1].styles.fillColor = 'white';
          data.row.cells[2].styles.fillColor = 'white';
          data.row.cells[3].styles.fillColor = 'white';

          data.row.cells[4].styles.fontStyle = 'bold';
          data.row.cells[4].styles.cellPadding = 0.7;
          data.row.cells[4].styles.fillColor = '#f7f7f7';

          data.row.cells[5].styles.fontStyle = 'bold';
          data.row.cells[5].styles.cellPadding = 0.7;
          data.row.cells[5].styles.fillColor = '#f7f7f7';
        } else if (data.section === 'body') {
          data.cell.styles.fillColor = 'white';
        }
      },
      columnStyles: {
        0: {
          valign: 'middle',
        },
        1: {
          valign: 'middle',
        },
        2: {
          valign: 'middle',
        },
        3: {
          valign: 'middle',
        },
        4: {
          valign: 'middle',
          halign: 'right',
          cellWidth: 20,
        },
        5: {
          valign: 'middle',
          halign: 'right',
          cellWidth: 20,
        },
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(
    options.FontSize === 'default' ? DEFAULT_TEXT_MEDIUM : LARGE_TEXT_MEDIUM
  ); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }

  await doc.save(pdfName);
};

const generateAttachmentPriceOfferReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();
  const { checkAndbuildSheetName } = useReport();

  if (!projectCalculation.value) return;

  const wb = utils.book_new();

  const xlsxName = createReportName(type, ReportFileType.XLSX);

  const sheetName = checkAndbuildSheetName(
    projectCalculation.value.Name,
    wb.SheetNames
  );

  wb.Props = {
    Title: `${translate('reports.material-order-report')}`,
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(sheetName);

  const offerData: (string | number)[][] = [];

  offerData.push([sheetName]);
  offerData.push(['']);

  await createHeaderXlsx(offerData);
  await createProjectInfoXlsx(offerData);

  offerData.push([
    `${translate('labels.zip')}`,
    `${translate('labels.description')}`,
    `${translate('labels.comments')}`,
    `${translate('labels.unit')}`,
    `${translate('labels.amount')}`,
    `${translate('labels.sum')}`,
  ]);

  let elements = projectCalculation.value.BuildingElements.filter(
    i =>
      (i.Amount && i.Amount > 0) ||
      (i.UnitPrice?.value && i.UnitPrice.value > 0)
  );

  if (elements) {
    let postnr = 1;
    elements = elements.sort((a, b) =>
      (a.SortOrder ?? -1) > (b.SortOrder ?? -1)
        ? 1
        : (b.SortOrder ?? -1) > (a.SortOrder ?? -1)
        ? -1
        : 0
    );

    elements.forEach(ele => {
      const row = [
        '',
        ele.Name ?? '',
        options.IncludeComments ? ele.Comments ?? '' : '',
        ele.Unit?.value ?? '',
        options.IncludeAmounts
          ? ele.Amount?.toLocaleString(defaultLocaleString, {
              maximumFractionDigits: 2,
            }) ?? ''
          : '',
        options.IncludeElementSums && ele.SumTotal.value
          ? ele.SumTotal.value.toLocaleString(defaultLocaleString, {
              maximumFractionDigits: 2,
            })
          : '',
      ];

      offerData.push(['', '', '', '', '']);
      offerData.push(row);

      let filteredItems = ele.BuildingItems.filter(
        i =>
          (i.Amount && i.Amount > 0) ||
          (i.UnitPrice.value && i.UnitPrice.value > 0)
      );

      filteredItems = filteredItems.sort((a, b) =>
        (a.BuildingElement?.SortOrder ?? -1) >
        (b.BuildingElement?.SortOrder ?? -1)
          ? 1
          : (b.BuildingElement?.SortOrder ?? -1) >
            (a.BuildingElement?.SortOrder ?? -1)
          ? -1
          : 0
      );

      if (options.IncludeBuildingItems) {
        filteredItems.forEach(item => {
          const row = [
            postnr++,
            `${item.Name ?? ''} ${
              item.PriceListItemName && options.ShowProductName
                ? `(${item.PriceListItemName})`
                : ''
            }`,
            options.IncludeComments ? item.Comments ?? '' : '',
            item.Unit ?? '',
            options.IncludeAmounts
              ? item.AmountCalculated.value?.toLocaleString(
                  defaultLocaleString,
                  {
                    maximumFractionDigits: 2,
                  }
                ) ?? ''
              : '',
            options.IncludeItemSums && item.SumTotalWithAdditions.value
              ? item.SumTotalWithAdditions.value.toLocaleString(
                  defaultLocaleString,
                  {
                    maximumFractionDigits: 2,
                  }
                )
              : '',
          ];

          offerData.push(row);
        });
      }
    });

    const MVA = 0.25;
    let sumExclMva = 0;
    let sumInclMva = 0;
    let MvaOnly = 0;

    elements.forEach(ele => {
      sumExclMva += ele.SumTotal.value ?? 0;
    });

    MvaOnly = sumExclMva * MVA;
    sumInclMva = sumExclMva + MvaOnly;

    const fillRow = ['', '', '', '', ''];
    const sumExclMvaRow = [
      '',
      '',
      '',
      '',
      `${translate('labels.sum-exclude-mva')}`,
      sumExclMva.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 2,
      }),
    ];
    const mvaRow = [
      '',
      '',
      '',
      '',
      `${translate('labels.sum-mva-only')}`,
      MvaOnly.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 2,
      }),
    ];
    const sumInclMvaRow = [
      '',
      '',
      '',
      '',
      `${translate('labels.sum-include-mva')}`,
      sumInclMva.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 2,
      }),
    ];

    offerData.push(fillRow);
    offerData.push(sumExclMvaRow);
    offerData.push(mvaRow);
    offerData.push(sumInclMvaRow);
  }

  const ws = utils.aoa_to_sheet(offerData);
  wb.Sheets[sheetName] = ws;

  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });

  downloadExcel(xlsxName, wbout);
};

export const generateAttachmentPriceOfferReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generateAttachmentPriceOfferReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generateAttachmentPriceOfferReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
