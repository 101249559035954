

























































































import { ExportType } from '@/constants/enum';
import { ref, defineComponent, PropType } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { BuildingTypeTemplate } from '@/models/library/interfaces';
import { useExport } from '@/modules/export';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    template: {
      type: Object as PropType<BuildingTypeTemplate>,
      required: true,
    },
  },

  setup(props) {
    const { exportTemplate } = useExport();

    const dialog = ref<boolean>(false);
    const loading = ref(false);
    const type = ref<ExportType>(ExportType.XML);

    const submit = async () => {
      loading.value = true;
      if (props.template.Id && type.value != null) {
        const response = await exportTemplate(props.template);
        if (response) {
          try {
            const xmlPayload = {
              BuildingTypeTemplate: response,
            };

            const blobParts = JSON.stringify(xmlPayload);
            let blobType = '';

            blobType = 'application/json';

            const blob = new Blob([blobParts], {
              type: blobType,
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${props.template.Name}.${'json'}`;
            link.click();
            link.remove();
          } catch (e) {
            console.log(e);
          }
        }
      }
      loading.value = false;
      dialog.value = false;
    };

    return {
      submit,
      dialog,
      loading,
      type,
      ExportType,
    };
  },
});
