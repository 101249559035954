/* 
  delete cache
  https://stackoverflow.com/a/46597656
*/

caches.keys().then(function(names) {
  for (const name of names) caches.delete(name);
});

/*
  https://medium.com/@nekrtemplar/self-destroying-serviceworker-73d62921d717
  https://github.com/NekR/self-destroying-sw
*/

self.addEventListener('install', function(e) {
  self.skipWaiting();
});

self.addEventListener('activate', function(e) {
  self.registration
    .unregister()
    .then(function() {
      return self.clients.matchAll();
    })
    .then(function(clients) {
      clients.forEach(client => client.navigate(client.url));
    });
});
