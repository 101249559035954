import { reactive, toRefs } from '@vue/composition-api';
import router from '@/router/index';
import {
  CustomError,
  Feedback,
  UserApplicationSettings,
} from '@/models/common/interfaces';
import { useApi } from './api';
import { isStorageSupported, isSuccess } from '@/helpers/common';
import { useSnackbar } from './snackbar';
import { useErrorModal } from './error';
import { Ns3451 } from '@/models/standards/ns3451/interfaces';
import { ACTIVE_LOCALE } from '@/constants/common';
import { Language, LOCALES } from '@/localization/locales';
import { defaultLocale, i18n } from '@/localization/index';
import moment from 'moment';
import { PROJECT_CALCULATION_BUILDING_ELEMENT_PATH } from '@/constants/routes';

interface State {
  userSettings?: UserApplicationSettings;
  Ns3451?: Array<Ns3451>;
  error?: CustomError;
  Ns3451Children?: Array<Ns3451>;
  feedback: boolean;
  currentLocale?: Language;
  userReadPatchNotes: boolean;
}

const state = reactive<State>({
  userSettings: undefined,
  Ns3451: undefined,
  error: undefined,
  Ns3451Children: undefined,
  feedback: false,
  currentLocale: undefined,
  userReadPatchNotes: true,
});

export const useGlobal = () => {
  const { snack } = useSnackbar();
  const { errorModal } = useErrorModal();

  const isOnRoute = (route: string) => {
    return router.currentRoute.path === route;
  };

  const isIsolatingElement = () => {
    return router.currentRoute.path.includes(
      PROJECT_CALCULATION_BUILDING_ELEMENT_PATH
    );
  };

  const appVersion = () => {
    let version = '';

    if (process.env.VUE_APP_VERSION) {
      version = process.env.VUE_APP_VERSION;
    } else {
      version = 'x.x.xx';
    }
    return version;
  };

  const requestFeedback = () => {
    state.feedback = true;
  };

  const initLanguage = () => {
    if (state.currentLocale?.value) {
      return;
    }

    LOCALES.forEach(lang => {
      if (lang.value === defaultLocale) {
        state.currentLocale = lang;
      }
    });

    if (isStorageSupported()) {
      const chosen = localStorage.getItem(ACTIVE_LOCALE);
      if (chosen) {
        LOCALES.forEach(lang => {
          if (lang.value === chosen) {
            state.currentLocale = lang;
          }
        });
      }
    }

    if (state.currentLocale?.value) {
      i18n.locale = state.currentLocale.value;
    }
  };

  const setLanguage = (lang: Language) => {
    if (isStorageSupported()) {
      localStorage.setItem(ACTIVE_LOCALE, lang.value);
    }
    moment.locale(lang.value);
    state.currentLocale = undefined;
    initLanguage();
  };

  const saveSettings = async (): Promise<boolean> => {
    let success = false;

    const { put } = useApi('/usersettings/base');

    if (!state.userSettings) return false;

    try {
      const response = await put(state.userSettings);
      if (isSuccess(response.status)) {
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const feedbackTime = () => {
    let feedback = false;
    if (state.userSettings?.Feedback) {
      if (!state.userSettings?.Feedback.DontAskUser) {
        if (!state.userSettings?.Feedback.HasProvidedFeedBack) {
          if (
            new Date(state.userSettings?.Feedback.NextTimeToAsk) < new Date()
          ) {
            feedback = true;
          }
        }
      }
    }
    return feedback;
  };

  const addFeedback = async (feedBack: Feedback): Promise<boolean> => {
    const { post } = useApi('/feedback');
    let success = false;

    try {
      const response = await post(feedBack);
      if (isSuccess(response.status)) {
        success = true;

        const today = new Date();
        const reminder = new Date();
        reminder.setDate(today.getDate() + 30);

        if (state.userSettings) {
          state.userSettings.Feedback = {
            DontAskUser: state.userSettings?.Feedback?.DontAskUser ?? false,
            HasProvidedFeedBack: true,
            NextTimeToAsk: reminder.toDateString(),
            _nextTimeToAsk: reminder.toDateString(),
          };
        }

        await saveSettings();
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    } finally {
      state.feedback = false;
    }

    return success;
  };

  const postponeFeedback = async (): Promise<void> => {
    const today = new Date();
    const reminder = new Date();
    reminder.setDate(today.getDate() + 14);

    if (state.userSettings) {
      state.userSettings.Feedback = {
        DontAskUser: state.userSettings?.Feedback?.DontAskUser ?? false,
        HasProvidedFeedBack: false,
        NextTimeToAsk: reminder.toDateString(),
        _nextTimeToAsk: reminder.toDateString(),
      };
    }

    await saveSettings();
    state.feedback = false;
  };

  const stopFeedbackReminders = async (): Promise<void> => {
    if (state.userSettings) {
      state.userSettings.Feedback = {
        DontAskUser: true,
        HasProvidedFeedBack: false,
        NextTimeToAsk: '',
        _nextTimeToAsk: new Date().toDateString(),
      };
    }

    await saveSettings();
  };

  const initUserSettings = async (): Promise<void> => {
    const defaultSettings: UserApplicationSettings = {
      ColumnSettings: [],
      ZoomPercentage: 1,
      CustomView1Columns: [],
      CustomView2Columns: [],
      LastUsedTemplates: [],
    };

    state.userSettings = defaultSettings;
    await postponeFeedback();
  };

  const getSettings = async (
    force?: boolean
  ): Promise<UserApplicationSettings | undefined> => {
    if (state.userSettings && !force) {
      return state.userSettings;
    }

    const { get } = useApi('/usersettings/base');

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        if (response.data != null) {
          state.userSettings = response.data;
        } else {
          await initUserSettings();
        }
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return state.userSettings;
  };

  const getStandards = async (
    force?: boolean
  ): Promise<Array<Ns3451> | undefined> => {
    if (state.Ns3451 && !force) {
      return state.Ns3451;
    }

    const { get } = useApi('/standard');

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.Ns3451 = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return state.Ns3451;
  };

  const getStandardChildren = async (
    force?: boolean
  ): Promise<Array<Ns3451> | undefined> => {
    if (state.Ns3451Children && !force) {
      return state.Ns3451Children;
    }

    const { get } = useApi('/standard/children');

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.Ns3451Children = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return state.Ns3451Children;
  };

  return {
    ...toRefs(state),
    getSettings,
    saveSettings,
    addFeedback,
    getStandards,
    getStandardChildren,
    stopFeedbackReminders,
    postponeFeedback,
    feedbackTime,
    requestFeedback,
    initLanguage,
    setLanguage,
    isOnRoute,
    isIsolatingElement,
    appVersion,
  };
};
