import { ProjectStartFilter } from '@/constants/enum';
import { PROJECT_OVERVIEW_PATH, PROJECT_START_PATH } from '@/constants/routes';
import { CustomError, Profession } from '@/models/common/interfaces';
import {
  CreateProject,
  Project,
  ProjectDetails,
  ProjectFilter,
  ProjectOverview,
  ProjectTotals,
  ProjectTotalsVM,
} from '@/models/projects/interfaces';
import { computed, reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import { useAuth } from './auth';
import { useErrorModal } from './error';
import { useSnackbar } from './snackbar';
import router from '@/router/index';
import { useCalculation } from './calculation';
import { isStorageSupported, isSuccess } from '@/helpers/common';
import { LS_ACTIVE_CALC_KEY, LS_ACTIVE_PROJECT_KEY } from '@/constants/common';
import {
  sumHours,
  sumMargin,
  sumMaterialsUE,
  sumProfit,
  sumSelfCostMaterialsSubContractors,
  sumTotal,
} from '@/helpers/calculation';

interface State {
  projects?: Array<ProjectOverview>;
  professions?: Array<Profession>;
  activeProjectFull: Project | undefined;
  activeProjectOverview: ProjectOverview | undefined;
  activeProjectDetails?: ProjectDetails;
  loading: boolean;
  loadingActive: boolean;
  buildingTotals: boolean;
  error?: CustomError;
  projectFilter: ProjectFilter;
}

const state = reactive<State>({
  loading: false,
  loadingActive: false,
  buildingTotals: false,
  projects: undefined,
  error: undefined,
  activeProjectFull: undefined,
  professions: undefined,
  activeProjectDetails: undefined,
  activeProjectOverview: undefined,
  projectFilter: {
    search: '',
    filter: ProjectStartFilter.Recent,
  },
});

export const useProject = () => {
  const { errorModal } = useErrorModal();
  const { snack } = useSnackbar();
  const { destructActiveCalculation, activeCalculation } = useCalculation();
  const { user } = useAuth();

  const checkForTotalOnMainProjects = (project: ProjectOverview): boolean => {
    return project.Total != null && project.Total > 0;
  };

  const lastLastModified = (project: ProjectOverview): string => {
    const last =
      project.Children &&
      project.Children.length > 0 &&
      project.Children.reduce((a, b) => {
        return new Date(a.LastModified) > new Date(b.LastModified) ? a : b;
      });

    return last ? last.LastModified : project.LastModified;
  };

  const filteredProjects = computed(() => {
    let filtered: Array<ProjectOverview> = [];
    if (state.projects && state.projects.length > 0) {
      switch (state.projectFilter.filter) {
        case ProjectStartFilter.Recent:
          filtered = state.projects.slice(0, 10);
          break;
        case ProjectStartFilter.MyProjects:
          filtered = state.projects.filter(project => {
            return project.Owner === user?.value?.Email;
          });
          break;
        case ProjectStartFilter.All:
          filtered = state.projects;
          break;
        default:
          filtered = state.projects;
          break;
      }

      if (state.projectFilter.search) {
        filtered = filtered.filter(p => {
          return (
            p.Name.toUpperCase().includes(
              state.projectFilter.search.toUpperCase()
            ) ||
            p.Children.find(c =>
              c.Name.toUpperCase().includes(
                state.projectFilter.search.toUpperCase()
              )
            ) ||
            p.ProjectNumber.toUpperCase().includes(
              state.projectFilter.search.toUpperCase()
            ) ||
            p.OwnedByName.toUpperCase().includes(
              state.projectFilter.search.toUpperCase()
            )
          );
        });
      }
      return filtered;
    }
  });

  const getProjects = async (
    force?: boolean
  ): Promise<Array<ProjectOverview> | undefined> => {
    const { get } = useApi('/project');
    if (state.projects && !force) {
      return state.projects;
    }

    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.projects = response.data as Array<ProjectOverview>;
        state.projects = user?.value?.IsAdmin
          ? state.projects
          : state.projects.filter(
              project => project.Shared || project.Owner === user?.value?.Email
            ) ?? [];

        if (state.projects) {
          //windows client allows element on main project, if a total exist, add it as a child to main project.
          state.projects.forEach(p => {
            if (checkForTotalOnMainProjects(p)) {
              const exists = p.Children.findIndex(calc => calc.Id === p.Id);
              if (exists && exists === -1) {
                p.Children.push(p);
              }
            }

            p.Children = p.Children.sort((a, b) =>
              ('' + a.ProjectNumber ?? '').localeCompare(
                b.ProjectNumber ?? '' + ''
              )
            );

            p.LastModified = lastLastModified(p);
          });
          state.projects = state.projects.sort((a, b) => {
            return +new Date(b.Created) - +new Date(a.Created);
          });
        }
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        console.log(e);
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return state.projects;
  };

  const buildProjectTotalsForCalculations = async () => {
    const { getCalculation, buildProjectCalculation } = useCalculation();

    if (state.projects && state.activeProjectOverview) {
      if (
        state.activeProjectOverview &&
        state.activeProjectOverview.Children &&
        state.activeProjectOverview.Children.length > 0
      ) {
        state.buildingTotals = true;
        for (const calc of state.activeProjectOverview.Children) {
          if (calc.Id) {
            const calculation = await getCalculation(calc.Id);

            if (calculation) {
              const projectCalc = await buildProjectCalculation(calculation);

              if (projectCalc) {
                const totals: ProjectTotalsVM = {
                  AmountControl: projectCalc.AmountControl ?? 0,
                  Hours: projectCalc.SumHours?.value ?? 0,
                  MaterialsSubContractors:
                    projectCalc.SumSelfCostMaterialsSubContractors?.value ?? 0,
                  MaterialsUE: projectCalc.SumMaterialsUE?.value ?? 0,
                  Profit: projectCalc.SumProfit?.value ?? 0,
                  SelfCost: projectCalc.SelfCost ?? 0,
                  SelfCostMaterialsSubContractors:
                    projectCalc.SumSelfCostMaterialsSubContractors?.value ?? 0,
                  Total: projectCalc.SumTotal?.value ?? 0,
                  SumMargin: projectCalc.SumMargin?.value ?? 0,
                };

                if (calc.Id === state.activeProjectOverview.Id) {
                  calc.TempProjectTotals = totals;
                  calc.ProjectTotals = totals;
                } else {
                  calc.ProjectTotals = totals;
                }
              }
            }
          }
        }

        state.activeProjectOverview.ProjectTotals = {
          AmountControl: state.activeProjectOverview.Children.reduce(
            (prev, cur) => {
              return prev + (cur.ProjectTotals?.AmountControl ?? 0);
            },
            0
          ),
          Hours: state.activeProjectOverview.Children.reduce((prev, cur) => {
            return prev + (cur.ProjectTotals?.Hours ?? 0);
          }, 0),
          MaterialsSubContractors: state.activeProjectOverview.Children.reduce(
            (prev, cur) => {
              return prev + (cur.ProjectTotals?.MaterialsSubContractors ?? 0);
            },
            0
          ),
          MaterialsUE: state.activeProjectOverview.Children.reduce(
            (prev, cur) => {
              return prev + (cur.ProjectTotals?.MaterialsUE ?? 0);
            },
            0
          ),
          Profit: state.activeProjectOverview.Children.reduce((prev, cur) => {
            return prev + (cur.ProjectTotals?.Profit ?? 0);
          }, 0),
          SelfCost: state.activeProjectOverview.Children.reduce((prev, cur) => {
            return prev + (cur.ProjectTotals?.SelfCost ?? 0);
          }, 0),
          SelfCostMaterialsSubContractors: state.activeProjectOverview.Children.reduce(
            (prev, cur) => {
              return (
                prev + (cur.ProjectTotals?.SelfCostMaterialsSubContractors ?? 0)
              );
            },
            0
          ),
          SumMargin: state.activeProjectOverview.Children.reduce(
            (prev, cur) => {
              return prev + (cur.ProjectTotals?.SumMargin ?? 0);
            },
            0
          ),
          Total: state.activeProjectOverview.Children.reduce((prev, cur) => {
            return prev + (cur.ProjectTotals?.Total ?? 0);
          }, 0),
        };

        state.buildingTotals = false;
      }
    }
  };

  const setActiveProjectFromProjectList = (id: string) => {
    if (state.projects) {
      const project: ProjectOverview | undefined = state.projects.find(
        proj => proj.Id == id
      );

      if (project) {
        state.activeProjectOverview = project;
        buildProjectTotalsForCalculations();
      }
    }
  };

  const getFullProjectById = async (
    projectId: string
  ): Promise<Project | undefined> => {
    const { get } = useApi(`/project/${projectId}/full`);
    state.loading = true;
    let project: Project | undefined = undefined;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        project = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;
    return project;
  };

  const getProjectOverviewById = async (
    projectId: string
  ): Promise<ProjectOverview | undefined> => {
    const { get } = useApi(`/project/${projectId}`);
    state.loading = true;
    let project: ProjectOverview | undefined = undefined;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        project = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;
    return project;
  };

  const createProject = async (
    newProject: CreateProject,
    isCalculation: boolean
  ): Promise<string> => {
    const { post } = useApi('/project');
    let projectId = '';
    state.loading = true;
    try {
      const response = await post(newProject);
      if (isSuccess(response.status)) {
        projectId = response.data.Id;
        snack(
          isCalculation ? 'snack.calc-created' : 'snack.project-created',
          true
        );
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return projectId;
  };

  const getActiveProjectDetails = async (
    projectId: string,
    force?: boolean
  ): Promise<ProjectDetails | undefined> => {
    if (state.activeProjectDetails && !force) return state.activeProjectDetails;
    const { get } = useApi(`/project/${projectId}/details`);

    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.activeProjectDetails = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;
    return state.activeProjectDetails;
  };

  const getDetails = async (
    projectId: string
  ): Promise<ProjectDetails | undefined> => {
    const { get } = useApi(`/project/${projectId}/details`);
    let details: ProjectDetails | undefined = undefined;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        details = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    return details;
  };

  const saveDetails = async (
    projectId: string,
    projectDetails: ProjectDetails
  ): Promise<boolean> => {
    const { put } = useApi(`/project/${projectId}/details`);
    let success = false;

    try {
      const response = await put(projectDetails);
      if (isSuccess(response.status)) {
        if (projectDetails.Id === state.activeProjectDetails?.Id) {
          state.activeProjectDetails = response.data;
        }

        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const destructActiveProject = async () => {
    state.activeProjectFull = undefined;
    state.activeProjectDetails = undefined;
    state.activeProjectOverview = undefined;
    state.projects = undefined;
    state.projectFilter = {
      search: '',
      filter: ProjectStartFilter.Recent,
    };
    await destructActiveCalculation();
    if (isStorageSupported()) {
      localStorage.removeItem(LS_ACTIVE_PROJECT_KEY);
      localStorage.removeItem(LS_ACTIVE_CALC_KEY);
    }
    if (router.currentRoute.path !== PROJECT_START_PATH) {
      await router.push(PROJECT_START_PATH);
    }
  };

  const closeActiveProject = async () => {
    state.activeProjectFull = undefined;
    state.activeProjectDetails = undefined;
    state.activeProjectOverview = undefined;
    state.projectFilter = {
      search: '',
      filter: ProjectStartFilter.Recent,
    };
    await destructActiveCalculation();
    if (isStorageSupported()) {
      localStorage.removeItem(LS_ACTIVE_PROJECT_KEY);
      localStorage.removeItem(LS_ACTIVE_CALC_KEY);
    }
    if (router.currentRoute.path !== PROJECT_START_PATH) {
      await router.push(PROJECT_START_PATH);
    }
  };

  const setActiveProject = async (
    projectId: string,
    routeToProjectOverview: boolean
  ): Promise<void> => {
    await destructActiveCalculation();

    if (!projectId) return;
    state.loadingActive = true;
    state.activeProjectFull = await getFullProjectById(projectId);
    state.activeProjectDetails = await getActiveProjectDetails(projectId, true);
    await getProjects();
    setActiveProjectFromProjectList(projectId);
    if (router.currentRoute.path !== PROJECT_OVERVIEW_PATH) {
      if (isStorageSupported() && projectId) {
        localStorage.setItem(LS_ACTIVE_PROJECT_KEY, projectId);
      }

      if (routeToProjectOverview) {
        await router.push(PROJECT_OVERVIEW_PATH);
      }
    }
    state.loadingActive = false;
  };

  const deleteProject = async (
    projectId: string,
    isCalculation: boolean,
    routeTo?: string
  ): Promise<boolean> => {
    const { deleteReq } = useApi(`/project/${projectId}`);
    state.loading = true;
    let success = false;
    try {
      const response = await deleteReq();
      if (isSuccess(response.status)) {
        if (state.activeProjectFull?.Id === projectId) {
          await destructActiveCalculation();
          await destructActiveProject();
        } else if (activeCalculation?.value?.Id === projectId) {
          await destructActiveCalculation();
        }
        state.projects?.forEach((p, i) => {
          if (p.Id === projectId && state.projects) {
            state.projects.splice(i, 1);
            snack('snack.project-deleted', true);
          }
          if (p.Children.some(c => c.Id === projectId)) {
            p.Children.forEach((c, j) => {
              if (c.Id === projectId && state.projects) {
                state.projects[i].Children.splice(j, 1);
                snack('snack.calc-deleted', true);
              }
            });
          }
        });

        if (routeTo && router.currentRoute.path !== routeTo) {
          await router.push(routeTo);
        }
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;
    return success;
  };

  const getProfessions = async (
    force?: boolean
  ): Promise<Array<Profession> | undefined> => {
    const { get } = useApi('/profession');

    if (state.professions && !force) {
      return state.professions;
    }

    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.professions = response.data.sort(
          (a: Profession, b: Profession) => {
            return parseInt(a.Code) - parseInt(b.Code);
          }
        );
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;
    return state.professions;
  };

  const getNewProjectNumber = async (
    parentProjectId?: string
  ): Promise<string | undefined> => {
    const { get } = useApi(
      `/projectnumbers/next${parentProjectId ? `/${parentProjectId}` : ''}`
    );
    let next: string | undefined = undefined;
    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        next = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;
    return next;
  };

  const updateActiveProjectTotal = (totals: ProjectTotals) => {
    const { projectCalculation } = useCalculation();

    if (
      state.activeProjectOverview?.ProjectTotals == null ||
      projectCalculation.value?.Id == null
    ) {
      return;
    }

    state.buildingTotals = true;

    const calcOverview = state.activeProjectOverview.Children.find(
      ow => ow.Id === projectCalculation.value?.Id
    );

    if (calcOverview) {
      calcOverview.ProjectTotals = {
        AmountControl: totals.AmountControl,
        Hours: sumHours(projectCalculation.value) ?? 0,
        MaterialsSubContractors:
          sumSelfCostMaterialsSubContractors(projectCalculation.value) ?? 0,
        MaterialsUE: sumMaterialsUE(projectCalculation.value) ?? 0,
        Profit: sumProfit(projectCalculation.value) ?? 0,
        SelfCost: totals.SelfCost,
        SelfCostMaterialsSubContractors:
          sumSelfCostMaterialsSubContractors(projectCalculation.value) ?? 0,
        SumMargin: sumMargin(projectCalculation.value) ?? 0,
        Total: sumTotal(projectCalculation.value),
      };

      calcOverview.Total =
        calcOverview.ProjectTotals.Total ?? calcOverview.Total;

      if (calcOverview.Id === state.activeProjectOverview.Id) {
        calcOverview.TempProjectTotals = calcOverview.ProjectTotals;
      }
    }

    state.activeProjectOverview.ProjectTotals = {};

    state.activeProjectOverview.ProjectTotals = {
      AmountControl: state.activeProjectOverview.Children.reduce(
        (prev, cur) => {
          return prev + (cur.ProjectTotals?.AmountControl ?? 0);
        },
        0
      ),
      Hours: state.activeProjectOverview.Children.reduce((prev, cur) => {
        return prev + (cur.ProjectTotals?.Hours ?? 0);
      }, 0),
      MaterialsSubContractors: state.activeProjectOverview.Children.reduce(
        (prev, cur) => {
          return prev + (cur.ProjectTotals?.MaterialsSubContractors ?? 0);
        },
        0
      ),
      SumMargin: state.activeProjectOverview.Children.reduce((prev, cur) => {
        return prev + (cur.ProjectTotals?.SumMargin ?? 0);
      }, 0),
      MaterialsUE: state.activeProjectOverview.Children.reduce((prev, cur) => {
        return prev + (cur.ProjectTotals?.MaterialsUE ?? 0);
      }, 0),
      Profit: state.activeProjectOverview.Children.reduce((prev, cur) => {
        return prev + (cur.ProjectTotals?.Profit ?? 0);
      }, 0),
      SelfCost: state.activeProjectOverview.Children.reduce((prev, cur) => {
        return prev + (cur.ProjectTotals?.SelfCost ?? 0);
      }, 0),
      SelfCostMaterialsSubContractors: state.activeProjectOverview.Children.reduce(
        (prev, cur) => {
          return (
            prev + (cur.ProjectTotals?.SelfCostMaterialsSubContractors ?? 0)
          );
        },
        0
      ),
      Total: state.activeProjectOverview.Children.reduce((prev, cur) => {
        return prev + (cur.ProjectTotals?.Total ?? 0);
      }, 0),
    };

    const parentIncluded = state.activeProjectOverview.Children.find(
      proj => proj.TempProjectTotals
    );

    if (parentIncluded && parentIncluded.TempProjectTotals) {
      if (
        state.activeProjectOverview.ProjectTotals
          .SelfCostMaterialsSubContractors != null &&
        parentIncluded.TempProjectTotals?.SelfCostMaterialsSubContractors !=
          null
      ) {
        state.activeProjectOverview.ProjectTotals.SelfCostMaterialsSubContractors +=
          parentIncluded.TempProjectTotals.SelfCostMaterialsSubContractors;
      }
      if (
        state.activeProjectOverview.ProjectTotals.Profit != null &&
        parentIncluded.TempProjectTotals?.Profit != null
      ) {
        state.activeProjectOverview.ProjectTotals.Profit +=
          parentIncluded.TempProjectTotals.Profit;
      }
      if (
        state.activeProjectOverview.ProjectTotals.Hours != null &&
        parentIncluded.TempProjectTotals?.Hours != null
      ) {
        state.activeProjectOverview.ProjectTotals.Hours +=
          parentIncluded.TempProjectTotals.Hours;
      }
      if (
        state.activeProjectOverview.ProjectTotals.Total != null &&
        parentIncluded.TempProjectTotals?.Total != null
      ) {
        state.activeProjectOverview.ProjectTotals.Total +=
          parentIncluded.TempProjectTotals.Total;
      }
      if (
        state.activeProjectOverview.ProjectTotals.SumMargin != null &&
        parentIncluded.TempProjectTotals?.SumMargin != null
      ) {
        state.activeProjectOverview.ProjectTotals.SumMargin +=
          parentIncluded.TempProjectTotals.SumMargin;
      }
    }

    state.buildingTotals = false;
  };

  const refreshActiveProject = async () => {
    if (state.activeProjectOverview?.Id) {
      state.activeProjectFull = await getFullProjectById(
        state.activeProjectOverview.Id
      );
      setActiveProjectFromProjectList(state.activeProjectOverview.Id);
    }
  };

  const firstProject = computed(() => {
    const first = false;

    if (!state.projects) return first;

    return state.projects.length === 0;
  });

  const setActiveProjectByNumber = async (projectNumber: string) => {
    await getProjects();
    const project = state.projects?.find(
      project => project.ProjectNumber === projectNumber
    );
    if (!project || !project.Id) {
      await router.push(PROJECT_START_PATH);
      return;
    }
    if (project.Id !== state.activeProjectDetails?.Id) {
      await setActiveProject(project.Id, false);
    }
  };

  return {
    ...toRefs(state),
    getProjects,
    setActiveProject,
    getProfessions,
    getNewProjectNumber,
    setActiveProjectByNumber,
    createProject,
    deleteProject,
    getDetails,
    getActiveProjectDetails,
    saveDetails,
    getFullProjectById,
    setActiveProjectFromProjectList,
    buildProjectTotalsForCalculations,
    refreshActiveProject,
    getProjectOverviewById,
    destructActiveProject,
    closeActiveProject,
    updateActiveProjectTotal,
    filteredProjects,
    firstProject,
  };
};
