

















































































































































































































































































































































































































































































import { defineComponent, onMounted } from '@vue/composition-api';
import VersionTitle from '@/components/version/version-title/index.vue';
import VersionNote from '@/components/version/version-note/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';
import { VersionNoteType } from '@/constants/enum';

export default defineComponent({
  components: {
    ContentBar,
    VersionTitle,
    VersionNote,
  },
  setup() {
    return {
      VersionNoteType,
      termsUrl: process.env.VUE_APP_TERMS_URL,
    };
  },
});
