var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hideTitle && _vm.calculations.length > 0)?_c('content-bar',{attrs:{"small":true,"title":_vm.$t('titles.calculations', { count: _vm.calculations.length })}}):_vm._e(),(_vm.calculations.length > 0)?_c('v-data-table',{staticClass:"elevation-1 calculation-table",attrs:{"headers":_vm.small ? _vm.CALCULATION_HEADERS_SMALL : _vm.PROJECT_HEADERS,"items":_vm.calculations,"item-key":"Id","hide-default-footer":!_vm.showHeaders,"disabled":_vm.loading,"loading-text":_vm.$t('info.loading'),"no-data-text":_vm.$t('info.no-data'),"no-results-text":_vm.$t('info.no-result')},on:{"dblclick:row":_vm.dblClickRow},scopedSlots:_vm._u([_vm._l((_vm.small ? _vm.CALCULATION_HEADERS_SMALL : _vm.PROJECT_HEADERS),function(h){return {key:("header." + (h.value)),fn:function(ref){return [_c('v-row',{key:h.text,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(h.text)?_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t(("headers." + (h.text.toLowerCase()))))+" ")]):_vm._e()]}}],null,true)},[(h.text)?_c('span',[_vm._v(_vm._s(_vm.$t(("headers-description." + (h.text.toLowerCase())))))]):_vm._e()])],1)],1)]}}}),{key:"item.Shared",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"shared",attrs:{"disabled":"","color":item.Shared ? 'secondary' : 'orange'}},[_vm._v(_vm._s(item.Shared ? 'mdi-eye' : 'mdi-eye-off'))])]}},{key:"item.calculations",fn:function(ref){
var item = ref.item;
return [(item.CalculationLockedTime)?_c('v-icon',{staticClass:"locked",attrs:{"color":"error"}},[_vm._v(" mdi-lock ")]):_vm._e(),_c('v-icon',{staticClass:"child-profession",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.getIconByProfessionCode( item.Profession && item.Profession.Code ? item.Profession.Code : item.ProfessionCode )))])]}},{key:"item.data-table-expand",fn:function(ref){return [_c('div',{staticClass:"placeholder"},[_c('v-icon',{attrs:{"right":""}})],1)]}},{key:"item.LastModified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.LastModified) .lang(("" + (_vm.$i18n.locale === 'no' ? 'nb' : _vm.$i18n.locale))) .format('Do MMMM YYYY'))+" ")]}},{key:"item.ProjectTotals.Total",fn:function(ref){
var item = ref.item;
return [(!_vm.buildingTotals)?_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.TempProjectTotals && item.TempProjectTotals.Total ? item.TempProjectTotals.Total.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }) : item.ProjectTotals && item.ProjectTotals.Total ? item.ProjectTotals.Total.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }) : '0')+" ")]):_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}})]}},{key:"item.ProjectTotals.SumMargin",fn:function(ref){
var item = ref.item;
return [(!_vm.buildingTotals)?_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.TempProjectTotals && item.TempProjectTotals.SumMargin ? item.TempProjectTotals.SumMargin.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }) : item.ProjectTotals && item.ProjectTotals.SumMargin ? item.ProjectTotals.SumMargin.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }) : '0')+" "),_c('span',{staticClass:"percentage"},[_vm._v(" "+_vm._s(item.TempProjectTotals && item.TempProjectTotals.SumMargin && item.TempProjectTotals.Total ? ("(" + (_vm.safePercentage( item.TempProjectTotals.Total, item.TempProjectTotals.SumMargin ).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, })) + "%)") : item.ProjectTotals && item.ProjectTotals.Total && item.ProjectTotals.SumMargin ? ("(" + (_vm.safePercentage( item.ProjectTotals.Total, item.ProjectTotals.SumMargin ).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, })) + "%)") : ''))])]):_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}})]}},{key:"item.Total",fn:function(ref){
var item = ref.item;
return [(!_vm.buildingTotals)?_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.Total ? item.Total.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }) : '0')+" ")]):_vm._e()]}},{key:"item.ProjectTotals.SelfCostMaterialsSubContractors",fn:function(ref){
var item = ref.item;
return [(!_vm.buildingTotals)?_c('div',{staticClass:"orange--text font-weight-medium"},[_vm._v(" "+_vm._s(item.TempProjectTotals && item.TempProjectTotals.SelfCostMaterialsSubContractors ? item.TempProjectTotals.SelfCostMaterialsSubContractors.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 0, } ) : item.ProjectTotals && item.ProjectTotals.SelfCostMaterialsSubContractors ? item.ProjectTotals.SelfCostMaterialsSubContractors.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 0, } ) : '0')+" "),_c('span',{staticClass:"percentage"},[_vm._v(" "+_vm._s(item.TempProjectTotals && item.TempProjectTotals.SelfCostMaterialsSubContractors && item.TempProjectTotals.Total ? ("(" + (_vm.safePercentage( item.TempProjectTotals.Total, item.TempProjectTotals.SelfCostMaterialsSubContractors ).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, })) + "%)") : item.ProjectTotals && item.ProjectTotals.SelfCostMaterialsSubContractors && item.ProjectTotals.Total ? ("(" + (_vm.safePercentage( item.ProjectTotals.Total, item.ProjectTotals.SelfCostMaterialsSubContractors ).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, })) + "%)") : ''))])]):_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}})]}},{key:"item.ProjectTotals.Hours",fn:function(ref){
var item = ref.item;
return [(!_vm.buildingTotals)?_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.TempProjectTotals && item.TempProjectTotals.Hours ? item.TempProjectTotals.Hours.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }) : item.ProjectTotals && item.ProjectTotals.Hours ? item.ProjectTotals.Hours.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }) : '0')+" ")]):_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}})]}},{key:"item.ProjectTotals.Profit",fn:function(ref){
var item = ref.item;
return [(!_vm.buildingTotals)?_c('div',{staticClass:"green--text font-weight-medium"},[_vm._v(" "+_vm._s(item.TempProjectTotals && item.TempProjectTotals.Profit ? item.TempProjectTotals.Profit.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }) : item.ProjectTotals && item.ProjectTotals.Profit ? item.ProjectTotals.Profit.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }) : '0')+" "),_c('span',{staticClass:"percentage"},[_vm._v(" "+_vm._s(item.TempProjectTotals && item.TempProjectTotals.Profit && item.TempProjectTotals.SelfCost ? ("(" + (_vm.safePercentage( item.TempProjectTotals.SelfCost, item.TempProjectTotals.Profit ).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, })) + "%)") : item.ProjectTotals && item.ProjectTotals.SelfCost && item.ProjectTotals.Profit ? ("(" + (_vm.safePercentage( item.ProjectTotals.SelfCost, item.ProjectTotals.Profit ).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, })) + "%)") : ''))])]):_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.importCalculation)?_c('v-btn-toggle',{attrs:{"color":"primary"}},[_c('v-menu',{attrs:{"close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"calculation-menu",attrs:{"icon":"","small":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('edit-project',{attrs:{"isMenuitem":true,"calulation":item}}),_c('export-project',{attrs:{"project":item,"isCalculation":true,"isMenuitem":true}}),(_vm.projects && _vm.projects.length > 1)?_c('copy-to-another-project',{attrs:{"calculation":item,"isMenuitem":true}}):_vm._e(),_c('lock-calculation',{attrs:{"calculation":item,"isMenuitem":true}}),_c('delete-project',{attrs:{"project":item,"isCalculation":true,"isMenuitem":true}})],1)],1)],1),_c('v-btn',{staticClass:"traverse-btn",attrs:{"small":"","outlined":"","loading":_vm.loadingId === item.Id},on:{"click":function($event){item.CalculationLockedTime
              ? _vm.traverse(_vm.PROJECT_CALCULATION_OVERVIEW_PATH, item)
              : _vm.traverse(_vm.PROJECT_CALCULATION_PATH, item)}}},[_vm._v(" "+_vm._s(item.CalculationLockedTime ? _vm.$t('buttons.go-calculation-overview') : _vm.$t('buttons.go-calculation'))+" "),_c('v-icon',{attrs:{"color":"primary","right":""}},[_vm._v(" mdi-open-in-app")])],1)],1):_c('v-btn',{staticClass:"import-add",attrs:{"small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.emitImport(item)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.import'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-import ")])],1)]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }