export const AUTH_KEY = 'kalkonline_id';
export const GRANT_TYPE_PW = 'password';

export const DEFAULT_LAYOUT = 'applayout-default';
export const PROTECTED_LAYOUT = 'applayout-protected';
export const LOADING_LAYOUT = 'applayout-loading';

export const LS_ACTIVE_PROJECT_KEY = 'kalkonline_ap';
export const LS_ACTIVE_CALC_KEY = 'kalkonline_ac';
export const LS_ACTIVE_BE_KEY = 'kalkonline_be';

export const EMOTION_HAPPY = 'Smile';
export const EMOTION_DONT_KNOW = 'DontKnow';
export const EMOTION_SAD = 'Sad';

export const FEEDBACKTYPE_GENERAL = 'General';
export const FEEDBACKTYPE_IMPROVEMENT = 'Improvement';
export const FEEDBACKTYPE_BUG = 'Bug';

export const ACTIVE_LOCALE = 'kalkonline_locale';

export const BLOB_PRICELIST_NAME = 'pricelists.json';

export const ONE_MEGABYTE = 1024 * 1024;

export const BUFFER_SIZE = 4 * ONE_MEGABYTE;
export const MAX_BUFFERS = 20;

export const LICENSE_PRICE_FULL_NO_MEMBERSHIP = 999;
export const LICENSE_PRICE_FULL_MEMBERSHIP = 829;
export const LICENSE_PRICE_ADDITION_NO_MEMBERSHIP = 219;
export const LICENSE_PRICE_ADDITION_MEMBERSHIP = 182;

export const UNITS = new Map<string, string>([
  ['KVM', 'm2'],
  ['KVMTR', 'm2'],
  ['LM', 'm'],
  ['MTR', 'm'],
  ['KUB', 'm3'],
  ['ST', 'stk'],
  ['LGD', 'stk'],
  ['BK', 'stk'],
  ['PK', 'pak'],
  ['PAKKE', 'pak'],
  ['RULL', 'rul'],
  ['SP', 'spa'],
  ['SPANN', 'spa'],
  ['KN', 'spa'],
  ['BKS', 'spa'],
  ['BOKS', 'spa'],
  ['POSE', 'pos'],
  ['SETT', 'set'],
  ['PALL', 'pal'],
  ['PL', 'pal'],
  ['SEKK', 'sek'],
  ['SK', 'sek'],
  ['FLASKE', 'fl'],
  ['FLK', 'fl'],
  ['LITER', 'ltr'],
  ['KILO', 'kg'],
  ['TONN', 'ton'],
]);
