import { createReportName, downloadExcel } from '@/helpers/reports';
import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ReportFileType, ReportType } from '@/models/report//enums';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { useCalculation } from '@/modules/calculation';
import { ReportOptions } from '@/models/report/interfaces';
import { utils, write } from 'xlsx';
import {
  getNameByProfessionCode,
  numberToAbsStringOrEmpty,
} from '@/helpers/common';
import {
  DEFAULT_TEXT_LARGE,
  DEFAULT_TEXT_MEDIUM,
  DEFAULT_TEXT_XMEDIUM,
  DEFAULT_TEXT_XSMALL,
  LARGE_TEXT_LARGE,
  LARGE_TEXT_MEDIUM,
  LARGE_TEXT_XMEDIUM,
  LARGE_TEXT_XSMALL,
} from '@/constants/styles';
import { useReport } from '@/modules/report';

export const generateProjectBuildingElementsReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { getCalculation, buildProjectCalculation } = useCalculation();

  if (options.Calculations.length === 0) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);
  const pdfName = createReportName(type, ReportFileType.PDF, true);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, true);

  const reportName = `${translate('reports.project-building-element-report')}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;
  doc.text(reportName, 14, first.finalY + 9);

  const totalData: (string | number)[][] = [];

  const MVA = 0.25;
  let projectSumExclMva = 0;
  let projectSumInclMva = 0;
  let projectMvaOnly = 0;

  let calculationCount = 0;
  for (const calc of options.Calculations) {
    if (calc.Id) {
      const calculation = await getCalculation(calc.Id);

      if (calculation) {
        const projectCalc = await buildProjectCalculation(calculation);
        if (!projectCalc || projectCalc.BuildingElements.length === 0) continue;
        calculationCount++;

        const head = [
          [
            translate('labels.zip'),
            translate('labels.production-line'),
            translate('labels.description'),
            translate('labels.unit'),
            translate('labels.amount'),
            translate('labels.unit-price'),
            translate('labels.sum'),
          ],
        ];

        const calcName = `${projectCalc.Name} - ${getNameByProfessionCode(
          projectCalc.Profession.Code
        )}`;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const first = (doc as any).autoTable.previous;

        autoTable(doc, {
          startY: calculationCount === 1 ? first.finalY + 14 : first.finalY + 6,
          head: [[calcName]],
          theme: 'plain',
          styles: {
            fontSize:
              options.FontSize === 'default'
                ? DEFAULT_TEXT_LARGE
                : LARGE_TEXT_LARGE,
            halign: 'left',
          },
        });

        const bEdata: (string | number)[][] = [];
        const buildingElementBreakPoints: Array<number> = [];

        const elements = projectCalc.BuildingElements;
        let postnr = 1;

        if (!elements) return;

        elements.forEach(ele => {
          buildingElementBreakPoints.push(bEdata.length);
          const row = [
            !options.IncludeBuildingItems ? postnr++ : '',
            ele.ProductionLine ?? '',
            ele.Name ?? '',
            ele.Unit?.value ?? '',
            numberToAbsStringOrEmpty(ele.Amount, 2),
            numberToAbsStringOrEmpty(ele.UnitPrice?.value, 2),
            numberToAbsStringOrEmpty(ele.SumTotal.value, 2),
          ];
          bEdata.push(row);

          if (options.IncludeBuildingItems) {
            ele.BuildingItems.forEach(item => {
              const row = [
                postnr++,
                '',
                item.Name ?? '',
                item.Unit ?? '',
                numberToAbsStringOrEmpty(item.AmountCalculated.value, 2),
                numberToAbsStringOrEmpty(item.UnitPriceWithAdditions?.value, 2),
                numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 2),
              ];
              bEdata.push(row);
            });
          }
        });

        const MVA = 0.25;
        let sumExclMva = 0;
        let sumInclMva = 0;
        let MvaOnly = 0;

        elements.forEach(ele => {
          sumExclMva += ele.SumTotal.value ?? 0;
        });

        MvaOnly = sumExclMva * MVA;
        sumInclMva = sumExclMva + MvaOnly;

        const sumExclMvaRow = [
          '',
          '',
          '',
          '',
          '',
          `${translate('labels.sum-exclude-mva')}`,
          numberToAbsStringOrEmpty(sumExclMva, 2),
        ];
        const mvaRow = [
          '',
          '',
          '',
          '',
          '',
          `${translate('labels.sum-mva-only')}`,
          numberToAbsStringOrEmpty(MvaOnly, 2),
        ];
        const sumInclMvaRow = [
          '',
          '',
          '',
          '',
          '',
          `${translate('labels.sum-include-mva')}`,
          numberToAbsStringOrEmpty(sumInclMva, 2),
        ];

        bEdata.push(['']);
        bEdata.push(sumExclMvaRow);
        bEdata.push(mvaRow);
        bEdata.push(sumInclMvaRow);

        projectSumExclMva = projectSumExclMva + sumExclMva;

        const calcTotalRow = [
          calcName,
          '',
          '',
          '',
          '',
          '',
          numberToAbsStringOrEmpty(sumExclMva, 2),
        ];

        totalData.push(calcTotalRow);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const second = (doc as any).autoTable.previous;
        autoTable(doc, {
          startY: second.finalY,
          head: [
            [
              options.IncludeBuildingItems
                ? `${translate('reports.building-elements-include-bi-report')}`
                : `${translate('reports.building-elements-report')}`,
            ],
          ],
          theme: 'plain',
          styles: {
            fontSize:
              options.FontSize === 'default'
                ? DEFAULT_TEXT_XMEDIUM
                : LARGE_TEXT_XMEDIUM,
            fontStyle: 'italic',
            halign: 'left',
          },
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const third = (doc as any).autoTable.previous;

        autoTable(doc, {
          startY: third.finalY + 2,
          head: head,
          body: bEdata,
          showHead: 'firstPage',
          headStyles: { fillColor: '#263238' },
          styles: {
            fontSize:
              options.FontSize === 'default'
                ? DEFAULT_TEXT_XSMALL
                : LARGE_TEXT_XSMALL,
          },
          didParseCell(data) {
            if (
              buildingElementBreakPoints.includes(data.row.index) &&
              data.section === 'body' &&
              options.IncludeBuildingItems
            ) {
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.fillColor = '#d6d8da';
            } else if (
              (data.section === 'body' &&
                data.row.index === bEdata.length - 1) ||
              data.row.index === bEdata.length - 2 ||
              data.row.index === bEdata.length - 3
            ) {
              data.row.cells[0].styles.fillColor = 'white';
              data.row.cells[1].styles.fillColor = 'white';
              data.row.cells[2].styles.fillColor = 'white';
              data.row.cells[3].styles.fillColor = 'white';
              data.row.cells[4].styles.fillColor = 'white';

              data.row.cells[5].styles.fontStyle = 'bold';
              data.row.cells[5].styles.cellPadding = 0.7;
              data.row.cells[5].styles.fillColor = '#f7f7f7';

              data.row.cells[6].styles.fontStyle = 'bold';
              data.row.cells[6].styles.cellPadding = 0.7;
              data.row.cells[6].styles.fillColor = '#f7f7f7';
            } else if (
              data.section === 'body' &&
              options.IncludeBuildingItems
            ) {
              data.cell.styles.fillColor = 'white';
            }
          },
          columnStyles: {
            0: {
              valign: 'middle',
            },
            1: {
              valign: 'middle',
            },
            2: {
              valign: 'middle',
            },
            3: {
              valign: 'middle',
            },
            4: {
              valign: 'middle',
            },
            5: {
              valign: 'middle',
              halign: 'right',
              cellWidth: 20,
            },
            6: {
              valign: 'middle',
              halign: 'right',
              cellWidth: 20,
            },
          },
        });
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const totalPos = (doc as any).autoTable.previous;

  autoTable(doc, {
    startY: calculationCount === 1 ? totalPos.finalY + 14 : totalPos.finalY + 6,
    head: [['Total']],
    theme: 'plain',
    styles: {
      fontSize:
        options.FontSize === 'default' ? DEFAULT_TEXT_LARGE : LARGE_TEXT_LARGE,
      halign: 'left',
    },
  });

  projectMvaOnly = projectSumExclMva * MVA;
  projectSumInclMva = projectSumExclMva + projectMvaOnly;

  const fillRow = ['', '', '', '', ''];
  const sumExclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-exclude-mva')}`,
    numberToAbsStringOrEmpty(projectSumExclMva, 2),
  ];
  const mvaRow = [
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-mva-only')}`,
    numberToAbsStringOrEmpty(projectMvaOnly, 2),
  ];
  const sumInclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-include-mva')}`,
    numberToAbsStringOrEmpty(projectSumInclMva, 2),
  ];

  totalData.push(fillRow);
  totalData.push(sumExclMvaRow);
  totalData.push(mvaRow);
  totalData.push(sumInclMvaRow);

  const totalHead = [
    [translate('labels.projects'), '', '', '', '', '', translate('labels.sum')],
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const totalTablePos = (doc as any).autoTable.previous;

  autoTable(doc, {
    startY: totalTablePos.finalY + 2,
    head: totalHead,
    body: totalData,
    showHead: 'firstPage',
    headStyles: { fillColor: '#263238' },
    styles: {
      fontSize:
        options.FontSize === 'default'
          ? DEFAULT_TEXT_XSMALL
          : LARGE_TEXT_XSMALL,
    },
    didParseCell(data) {
      if (
        (data.section === 'body' && data.row.index === totalData.length - 1) ||
        data.row.index === totalData.length - 2 ||
        data.row.index === totalData.length - 3
      ) {
        data.row.cells[0].styles.fillColor = 'white';
        data.row.cells[1].styles.fillColor = 'white';
        data.row.cells[2].styles.fillColor = 'white';
        data.row.cells[3].styles.fillColor = 'white';
        data.row.cells[4].styles.fillColor = 'white';

        data.row.cells[5].styles.fontStyle = 'bold';
        data.row.cells[5].styles.cellPadding = 0.7;
        data.row.cells[5].styles.fillColor = '#f7f7f7';

        data.row.cells[6].styles.fontStyle = 'bold';
        data.row.cells[6].styles.cellPadding = 0.7;
        data.row.cells[6].styles.fillColor = '#f7f7f7';
      } else if (data.section === 'body') {
        data.cell.styles.fillColor = 'white';
      }
    },
    columnStyles: {
      0: {
        valign: 'middle',
      },
      1: {
        valign: 'middle',
      },
      2: {
        valign: 'middle',
      },
      3: {
        valign: 'middle',
      },
      4: {
        valign: 'middle',
      },
      5: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 20,
      },
      6: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 20,
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(
    options.FontSize === 'default' ? DEFAULT_TEXT_MEDIUM : LARGE_TEXT_MEDIUM
  ); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }

  await doc.save(pdfName);
};

export const generateProjectBuildingElementsReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { getCalculation, buildProjectCalculation } = useCalculation();
  const { checkAndbuildSheetName } = useReport();

  if (options.Calculations.length === 0) return;

  const wb = utils.book_new();
  const xlsxName = createReportName(type, ReportFileType.XLSX, true);

  for (const calc of options.Calculations) {
    if (calc.Id) {
      const calculation = await getCalculation(calc.Id);

      if (calculation) {
        const projectCalc = await buildProjectCalculation(calculation);
        if (!projectCalc) continue;

        const sheetName = checkAndbuildSheetName(
          projectCalc.Name,
          wb.SheetNames
        );

        wb.Props = {
          Title: `${translate('reports.project-building-element-report')}`,
          CreatedDate: new Date(),
        };

        wb.SheetNames.push(sheetName);

        const bEdata: (string | number)[][] = [];

        bEdata.push([sheetName]);
        bEdata.push(['']);

        await createHeaderXlsx(bEdata);
        await createProjectInfoXlsx(bEdata);

        let elements = projectCalc.BuildingElements.filter(
          i =>
            (i.Amount && i.Amount > 0) ||
            (i.UnitPrice?.value && i.UnitPrice.value > 0)
        );

        if (elements) {
          bEdata.push([
            `${translate('labels.zip')}`,
            `${translate('labels.production-line')}`,
            `${translate('labels.description')}`,
            `${translate('labels.unit')}`,
            `${translate('labels.amount')}`,
            `${translate('labels.unit-price')}`,
            `${translate('labels.sum')}`,
          ]);
          let postnr = 1;
          elements = elements.sort((a, b) =>
            (a.SortOrder ?? -1) > (b.SortOrder ?? -1)
              ? 1
              : (b.SortOrder ?? -1) > (a.SortOrder ?? -1)
              ? -1
              : 0
          );

          elements.forEach(ele => {
            const row = [
              !options.IncludeBuildingItems ? postnr++ : '',
              ele.ProductionLine ?? '',
              ele.Name ?? '',
              ele.Unit?.value ?? '',
              numberToAbsStringOrEmpty(ele.Amount, 2),
              numberToAbsStringOrEmpty(ele.UnitPrice?.value, 2),
              numberToAbsStringOrEmpty(ele.SumTotal.value, 2),
            ];
            bEdata.push(row);

            if (options.IncludeBuildingItems) {
              ele.BuildingItems.forEach(item => {
                const row = [
                  postnr++,
                  '',
                  item.Name ?? '',
                  item.Unit ?? '',
                  numberToAbsStringOrEmpty(item.AmountCalculated.value, 2),
                  numberToAbsStringOrEmpty(
                    item.UnitPriceWithAdditions.value,
                    2
                  ),
                  numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 2),
                ];
                bEdata.push(row);
              });
            }
          });

          const MVA = 0.25;
          let sumExclMva = 0;
          let sumInclMva = 0;
          let MvaOnly = 0;

          elements.forEach(ele => {
            sumExclMva += ele.SumTotal.value ?? 0;
          });

          MvaOnly = sumExclMva * MVA;
          sumInclMva = sumExclMva + MvaOnly;

          const sumExclMvaRow = [
            '',
            '',
            '',
            '',
            '',
            `${translate('labels.sum-exclude-mva')}`,
            numberToAbsStringOrEmpty(sumExclMva, 2),
          ];
          const mvaRow = [
            '',
            '',
            '',
            '',
            '',
            `${translate('labels.sum-mva-only')}`,
            numberToAbsStringOrEmpty(MvaOnly, 2),
          ];
          const sumInclMvaRow = [
            '',
            '',
            '',
            '',
            '',
            `${translate('labels.sum-include-mva')}`,
            numberToAbsStringOrEmpty(sumInclMva, 2),
          ];

          bEdata.push(['']);
          bEdata.push(sumExclMvaRow);
          bEdata.push(mvaRow);
          bEdata.push(sumInclMvaRow);

          const ws = utils.aoa_to_sheet(bEdata);
          wb.Sheets[sheetName] = ws;
        }
      }
    }
  }
  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
  downloadExcel(xlsxName, wbout);
};

export const generateProjectBuildingElementsReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generateProjectBuildingElementsReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generateProjectBuildingElementsReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
